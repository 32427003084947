import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-2"},[_c(VCardTitle,[_vm._v(" Settings ")]),_c(VContainer,[_c('div',{staticClass:"checkbox-group"},[_c(VCheckbox,{staticStyle:{"margin-top":"-1rem"},attrs:{"color":_vm.profile.greenhouseGas ? 'primary' : ''},on:{"change":_vm.updateGreenhouseGas},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_c(VIcon,{attrs:{"color":_vm.profile.greenhouseGas ? 'primary' : ''}},[_vm._v("mdi-molecule-co2")]),_vm._v(" Greenhouse Gas Calculations (future) ")],1)]},proxy:true}]),model:{value:(_vm.profile.greenhouseGas),callback:function ($$v) {_vm.$set(_vm.profile, "greenhouseGas", $$v)},expression:"profile.greenhouseGas"}}),_c(VCheckbox,{staticStyle:{"margin-top":"-1rem","margin-right":"20px"},attrs:{"color":_vm.profile.showScreenSize ? 'primary' : ''},on:{"change":_vm.updateShowScreenSize},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_c(VIcon,{attrs:{"color":_vm.profile.showScreenSize ? 'primary' : ''}},[_vm._v("mdi-monitor")]),_vm._v(" Show screen size icon ")],1)]},proxy:true}]),model:{value:(_vm.profile.showScreenSize),callback:function ($$v) {_vm.$set(_vm.profile, "showScreenSize", $$v)},expression:"profile.showScreenSize"}}),_c(VCheckbox,{staticStyle:{"margin-top":"-1rem","margin-right":"20px"},attrs:{"color":_vm.profile.greenhouseGas ? 'primary' : ''},on:{"change":_vm.updateTeams},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_c(VIcon,{attrs:{"color":_vm.profile.enableTeams ? 'primary' : ''}},[_vm._v("mdi-account-group")]),_vm._v(" Teams ")],1),_c(VTooltip,{attrs:{"max-width":"400","bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary"}},on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v("Teams is designed for and can be enabled for Peeps accounts with many properties that are managed in teams. In this way, team members can focus on selected properties assigned to the team. ")])])]},proxy:true}]),model:{value:(_vm.profile.enableTeams),callback:function ($$v) {_vm.$set(_vm.profile, "enableTeams", $$v)},expression:"profile.enableTeams"}}),_c(VCheckbox,{staticStyle:{"margin-top":"-1rem","margin-right":"20px"},attrs:{"color":_vm.profile.includeParcelReference ? 'primary' : ''},on:{"change":_vm.updateParcelReference},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" Include Parcel Reference ")])]},proxy:true}]),model:{value:(_vm.profile.includeParcelReference),callback:function ($$v) {_vm.$set(_vm.profile, "includeParcelReference", $$v)},expression:"profile.includeParcelReference"}}),_c(VCheckbox,{staticStyle:{"margin-top":"-1rem","margin-right":"20px"},attrs:{"color":_vm.profile.enableNearbyParcels ? 'primary' : ''},on:{"change":_vm.updateNearbyParcels},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" Enable Nearby Parcels ")])]},proxy:true}]),model:{value:(_vm.profile.enableNearbyParcels),callback:function ($$v) {_vm.$set(_vm.profile, "enableNearbyParcels", $$v)},expression:"profile.enableNearbyParcels"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }